<template>
  <div>
    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility1"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideDepSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col cols="12" md="12">
            <b-form-group
              ref="icon"
              label="Icon"
              invalid-feedback="Icon is required."
            >
              <div v-if="logoloading == true">
                <b-spinner
                  variant="primary"
                  type="grow"
                  class="m-5"
                  label="Spinning"
                ></b-spinner>
              </div>
              <b-img
                ref="previewEl"
                src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                @click="$refs.fileProfile.click()"
                v-if="logoloading == false"
                style="border-radius: 10px"
                height="140px"
                width="140px"
              />
              <div v-if="logoloading == 'loaded'">
                <b-img
                  :src="catObj.icon"
                  height="140px"
                  width="140px"
                  style="border-radius: 10px"
                />
                <b-button
                  @click="deleteLogo()"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="btn-icon"
                  variant="danger"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>

                <br />
              </div>
              <input
                type="file"
                id="fileProfile"
                hidden
                ref="fileProfile"
                @change="selectlogo()"
                accept="image/*"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Category"
              invalid-feedback="Category is required."
              ref="CatName"
            >
              <b-form-input
                placeholder="Enter category name"
                name="classField"
                ref="CatName"
                v-model="catObj.category"
                @focusout="CheckCatName()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="AddCategory()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Publisher</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-center">
          <b-col md="10">
            <b-form-group
              label="Publisher"
              invalid-feedback="Publisher is required."
            >
              <b-form-input
                ref="publisher"
                v-model="pubObj.publisher"
                placeholder="Enter publisher name"
                @focusout="CheckPub()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="savePub()"
            >
              <b-spinner v-if="request" small type="grow" />
              <feather-icon v-else :icon="buttonIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          :tbody-tr-class="rowClass"
          show-empty
          :items="publishers"
          :fields="pubFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editPub(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deletePub(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Author</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-center">
          <b-col md="10">
            <b-form-group label="Author" invalid-feedback="Author is required.">
              <b-form-input
                ref="author"
                v-model="authObj.author"
                placeholder="Enter author name"
                @focusout="CheckAuth()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="saveAuth()"
            >
              <b-spinner v-if="request" small type="grow" />
              <feather-icon v-else :icon="buttonIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          :tbody-tr-class="rowClass"
          show-empty
          :items="authors"
          :fields="authFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editAuth(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteAuth(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Book Title"
              invalid-feedback="Book title is required."
              ref="title"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter fee title"
                name="classField"
                ref="title"
                v-model="myObj.title"
                @focusout="CheckTitle()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Book details"
              invalid-feedback="Book details is required."
              ref="desc"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter book details"
                name="classField"
                ref="desc"
                v-model="myObj.details"
                @focusout="CheckDesc()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Category"
              invalid-feedback="Category is required."
              ref="catId"
            >
              <v-select
                ref="catId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="feeTypeOptions"
                v-model="myObj.recurrence"
                placeholder="Add recurrence"
                @change="CheckRecurrence()"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="12">
                <b-form-group
                  label="Classes *"
                  invalid-feedback="classes  is required."
                  ref="class"
                >
                  <v-select
                    ref="class"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="classes"
                    label="name"
                    :reduce="(option) => option.id"
                    v-model="assignedClass"
                    placeholder="select classes"
                    multiple
                    @input="CheckClass()"
                  />
                </b-form-group>
              </b-col> -->

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-if="request == false"> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
          <!-- {{ mydefaultAmount }} {{ myObj.defaultAmount }} -->
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>
    <b-card v-if="showForm">
      <h3>Add Book</h3>
      <hr />
      <b-row class="lib-form align-items-center">
        <div class="p-1 lib-form-left">
          <b-form-group
            ref="cover"
            label="Book Cover"
            invalid-feedback="Book Cover is required."
          >
            <div v-if="coverLoading == true">
              <b-spinner
                variant="primary"
                type="grow"
                class="m-5"
                label="Spinning"
              ></b-spinner>
            </div>
            <b-img
              ref="previewEl"
              src="https://cdn.cloudious.net/file-1677914849290-677244852.png"
              @click="$refs.coverImg.click()"
              v-if="coverLoading == false"
              style="border-radius: 10%"
              height="180px"
              width="160px"
            />
            <div
              v-if="coverLoading == 'loaded'"
              class="d-flex align-items-center"
            >
              <b-img
                :src="myObj.cover"
                height="180px"
                width="160px"
                style="border-radius: 10%; border: 10px solid var(--primary)"
              />
              <b-button
                @click="deleteCover()"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="btn-icon ml-2"
                variant="danger"
              >
                <feather-icon icon="Trash2Icon" size="18" />
              </b-button>

              <br />
            </div>
            <input
              type="file"
              id="coverImg"
              hidden
              ref="coverImg"
              @change="selectCover()"
              accept="image/*"
            />
          </b-form-group>
        </div>
        <div class="p-1 lib-form-right">
          <b-col xl="3" lg="3" md="4">
            <b-form-group
              label="Book Title"
              invalid-feedback="Book title is required."
              ref="title"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter fee title"
                name="classField"
                ref="title"
                v-model="myObj.title"
                @focusout="CheckTitle()"
              />
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="3" md="4">
            <b-form-group
              label="Category"
              invalid-feedback="Category is required."
              ref="catId"
            >
              <feather-icon
                icon="Edit2Icon"
                @click="AddOpen()"
                style="
                  position: absolute;
                  right: 10px;
                  top: 0px;
                  cursor: pointer;
                "
              />
              <v-select
                ref="catId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="items"
                :reduce="(opt) => opt.id"
                :clearable="false"
                label="category"
                v-model="myObj.categoryID"
                placeholder="Select Category"
                @change="CheckCatId()"
              />
            </b-form-group>
          </b-col>

          <b-col xl="3" lg="3" md="4">
            <b-form-group
              label="Publisher"
              invalid-feedback="Publisher is required."
              ref="pubId"
            >
              <feather-icon
                icon="Edit2Icon"
                style="
                  position: absolute;
                  right: 10px;
                  top: 0px;
                  cursor: pointer;
                "
                @click="openPub()"
              />
              <v-select
                ref="pubId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="publishers"
                :reduce="(opt) => opt.id"
                :clearable="false"
                label="publisher"
                v-model="myObj.publisherID"
                placeholder="Select publisher"
                @change="CheckPubId()"
              />
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="3" md="4">
            <b-form-group
              label="Author"
              invalid-feedback="Author is required."
              ref="authId"
            >
              <feather-icon
                icon="Edit2Icon"
                @click="openAuth()"
                style="
                  position: absolute;
                  right: 10px;
                  top: 0px;
                  cursor: pointer;
                "
              />

              <v-select
                ref="authId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="authors"
                :reduce="(opt) => opt.id"
                :clearable="false"
                label="author"
                v-model="myObj.authorID"
                placeholder="Select Author"
                @change="CheckAuthId()"
              />
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="3" md="4">
            <b-form-group
              label="Book details"
              invalid-feedback="Book details is required."
              ref="desc"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter book details"
                name="classField"
                ref="desc"
                v-model="myObj.details"
                @focusout="CheckDesc()"
              />
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="3" md="4">
            <b-form-group
              label="PDF"
              invalid-feedback="PDF is required."
              ref="bookPdf"
            >
              <b-button
                variant="primary"
                :disabled="uploading"
                @click="$refs.pdf.click()"
                v-if="!mediaLoaded"
                block
              >
                <b-spinner
                  v-if="uploading"
                  small
                  variant="light"
                  label="Spinning"
                ></b-spinner>
                <div v-else>
                  <input
                    type="file"
                    id="pdf"
                    hidden
                    ref="pdf"
                    @change="uploadPdf()"
                    accept="application/pdf"
                  />
                  <span class="align-middle">Upload</span>
                </div>
              </b-button>
              <div v-else class="">
                <b-button variant="primary">
                  <a :href="myObj.pdf" target="_blank" class="text-light">
                    <feather-icon icon="BookIcon" size="20" />
                  </a>
                </b-button>
                <feather-icon
                  icon="Trash2Icon"
                  class="text-danger ml-1 cursor-pointer"
                  size="20"
                  @click="removePdf()"
                />
              </div>
            </b-form-group>
          </b-col>
        </div>
      </b-row>
      <b-row align-h="end">
        <b-col md="2">
          <b-button
            class="mb-50"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="showForm = false"
            block
          >
            <span>Close</span>
          </b-button>
        </b-col>
        <b-col md="2">
          <b-button
            class="mb-50"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="Add()"
            :disabled="request"
            block
          >
            <b-spinner v-if="request" small type="grow" />
            <span v-else> Save </span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row align-h="end" class="text-right" v-if="!showForm">
        <b-col class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1" cols="11">
          <!-- :class="{ 'my-collapsing adjust': !showAll }" -->
          <b-dropdown
            style="margin-inline: 2px; margin-bottom: 2px"
            v-for="(d, ind) in items"
            :key="ind"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            split
            :variant="current == d.id ? 'primary' : 'outline-primary'"
            :text="d.category"
            right
          >
            <!-- @click="LoadGrid(d.id)" -->
            <b-dropdown-item v-if="rights.edit" @click="EditCat(d)">
              Edit
            </b-dropdown-item>
            <b-dropdown-item v-if="rights.delete" @click="DeleteCat(d.id)">
              Delete
            </b-dropdown-item>

            <b-dropdown-divider />
          </b-dropdown>
          <!-- </div> -->
        </b-col>
        <b-col
          class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          style="margin-right: 8px"
          cols="*"
          v-if="rights.add"
        >
          <b-button
            @click="AddOpen()"
            style=""
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon rounded-circle mr-50"
          >
            <feather-icon size="16" icon="PlusIcon" />
          </b-button>

          <!-- <b-button
            @click="showAll = !showAll"
            style=""
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon rounded-circle"
          >
            <feather-icon
              v-if="!showAll"
              class="cursor-pointer"
              size="20"
              icon="ArrowDownIcon"
            />
            <feather-icon
              v-else
              class="cursor-pointer"
              icon="ArrowUpIcon"
              size="20"
            />
          </b-button> -->
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="!showForm">
        <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
          <b-button
            @click="openBook()"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Add Book</span>
          </b-button>
        </b-col>

        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          xl="10"
          lg="9"
          md="8"
          sm="12"
          cols="12"
        >
          <b-form-group class="">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="filters"
        :fields="fields"
        :busy="dataLoading"
        :current-page="currentPage"
        :per-page="perPage"
        show-empty
        responsive
        hover
      >
        <!-- selectable
        select-mode="single"
        @row-selected="EditBook($event[0])" -->
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(cover)="data">
          <b-img
            v-if="data.item.cover !== null && data.item.cover !== ''"
            rounded
            :src="data.item.cover"
            height="100px"
            width="100px"
            alt="cover"
          />
          <b-img
            v-else
            src="https://cdn.cloudious.net/file-1677914849290-677244852.png"
            rounded
            height="100px"
            width="100px"
            alt="cover"
          />
        </template>

        <template #cell(title)="data">
          <b-badge variant="light-primary">
            {{ data.item.title }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="mr-5 text-center">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-center">
            <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.top
              title="Edit"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="EditBook(data.item)"
            >
              <feather-icon icon="EditIcon" class="" />
            </b-button>
            <b-button
              v-if="rights.delete"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.top
              title="Delete"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="DeleteBook(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" class="" />
            </b-button>
          </div>
        </template>
      </b-table>
      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="filters.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { TheMask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BModal,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BMediaAside,
    BImg,
    BMedia,
    BLink,
    BForm,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    TheMask,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadGrid();
      this.LoadData(0);
      this.LoadPub();
      this.LoadAuth();
    }

    // this.LoadGrid();
    // this.LoadData(0);
    // this.LoadPub();
    // this.LoadAuth();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filters: function () {
      return this.gridData.filter((pro) => {
        return pro.title.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      showAll: false,
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,
      visibility2: false,
      visibility3: false,

      sidebarTitle: "Add Class",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      coverLoading: false,
      searchQuery: "",
      currentPage: 1,
      perPage: 30,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "cover", key: "cover" },
        { label: "title", key: "title" },
        { label: "details", key: "details" },
        { key: "actions", label: "actions" },
      ],
      pubFields: [
        { label: "Publisher", key: "publisher" },
        { key: "actions", label: "actions" },
      ],
      authFields: [
        { label: "Author", key: "author" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      gridData: [],
      selected: null,
      rangeDate: null,
      myObj: {
        id: 0,
        categoryID: 0,
        authorID: 0,
        authorID: 0,
        publisherID: 0,
        title: "",
        details: "",
        cover: "",
        pdf: "",
        status: "active",
        tag: "",
        campusID: this.$store.state.userData.cId,
      },

      assignedClass: null,
      classes: [],
      publishers: [],
      authors: [],
      showForm: false,
      buttonIcon: "",
      catObj: {
        id: 0,
        category: "",
        icon: "",
        status: "",
        sequence: 0,
        campusID: this.$store.state.userData.cId,
      },
      logoloading: false,
      pubObj: {
        id: 0,
        publisher: "",
        sequence: 0,
        campusID: this.$store.state.userData.cId,
      },
      pubObj: {
        id: 0,
        publisher: "",
        sequence: 0,
        campusID: this.$store.state.userData.cId,
      },
      authObj: {
        id: 0,
        publisher: "",
        sequence: 0,
        campusID: this.$store.state.userData.cId,
      },

      detailObj: {},
      timeout: 10000,
      current: 0,
      mediaLoaded: false,
      uploading: false,
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    openBook() {
      this.myObj = {
        id: 0,
        categoryID: 0,
        authorID: 0,
        authorID: 0,
        publisherID: 0,
        title: "",
        details: "",
        cover: "",
        pdf: "",
        status: "active",
        tag: "",
        campusID: this.$store.state.userData.cId,
      };
      //   this.sidebarTitle = "Add book";
      //   this.sidebarButton = "Save";
      //   this.visibility = true;
      this.showForm = true;
      this.mediaLoaded = false;
      this.coverLoading = false;
      var elem = this.$refs["desc"];
      elem.state = undefined;
      var elem = this.$refs["title"];
      elem.state = undefined;
      var elem = this.$refs["catId"];
      elem.state = undefined;
      var elem = this.$refs["pubId"];
      elem.state = undefined;
      var elem = this.$refs["authId"];
      elem.state = undefined;
    },
    EditBook(item) {
      this.myObj = item;
      this.showForm = true;
      if (this.myObj.cover == null || this.myObj.cover == "") {
        this.coverLoading = false;
      } else this.coverLoading = "loaded";
      if (this.myObj.pdf == null || this.myObj.pdf == "") {
        this.mediaLoaded = false;
      } else this.mediaLoaded = true;
      var elem = this.$refs["desc"];
      elem.state = undefined;
      var elem = this.$refs["title"];
      elem.state = undefined;
      var elem = this.$refs["catId"];
      elem.state = undefined;
      var elem = this.$refs["pubId"];
      elem.state = undefined;
      var elem = this.$refs["authId"];
      elem.state = undefined;
    },
    removePdf() {
      this.myObj.pdf = null;
      this.mediaLoaded = false;
      this.$refs.pdf.value = null;
    },
    uploadPdf() {
      if (this.$refs.pdf.files[0] !== "") {
        this.uploading = true;
        this.mediaLoaded = false;

        let formData = new FormData();
        formData.append("file", this.$refs.pdf.files[0]);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            this.myObj.pdf = fn1;
            // console.log(this.myObj.pdf);
            this.uploading = false;
            this.mediaLoaded = true;
            this.CheckPdf();
          })
          .catch((err) => {
            console.log(err);
            this.uploading = false;
          });
      }
    },
    deleteCover() {
      this.myObj.cover = null;
      this.coverLoading = false;
      this.$refs.coverImg.value = null;
    },
    selectCover() {
      if (this.$refs.coverImg.files[0] !== "") {
        this.coverLoading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.coverImg.files[0]);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            this.myObj.cover = fn1;
            // console.log(this.myObj.cover);
            this.coverLoading = "loaded";
          })
          .catch((err) => {
            console.log(err);
            this.coverLoading = false;
          });
      }
    },

    async DeleteBook(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Books/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Book removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadGrid();
      }
    },
    async LoadPub() {
      var obj = {
        url:
          this.$store.state.domain +
          "BooksPublisher?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.publishers = await this.get(obj);
      // console.log("pub", this.publishers);
    },

    openPub() {
      this.pubObj = {
        id: 0,
        publisher: "",
        sequence: 0,
        campusID: this.$store.state.userData.cId,
      };

      this.sidebarButton = "Save";
      this.visibility2 = true;
      this.buttonIcon = "PlusIcon";

      var elem = this.$refs["publisher"];
      elem.state = undefined;
    },
    editPub(item) {
      this.pubObj = item;
      this.buttonIcon = "Edit2Icon";
      // console.log(this.pubObj);
    },

    CheckPub() {
      var elem = this.$refs["publisher"];
      if (this.pubObj.publisher == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async savePub() {
      this.CheckPub();
      if (this.CheckPub() == true) {
        if (this.pubObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "BooksPublisher?db=" +
              this.$store.state.userData.db,
            body: this.pubObj,
            message: "Book Publisher added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.LoadPub();
            this.pubObj = {
              id: 0,
              publisher: "",
              sequence: 0,
              campusID: this.$store.state.userData.cId,
            };
            var elem = this.$refs["publisher"];
            elem.state = undefined;
          }
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "BooksPublisher/" +
              this.pubObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.pubObj,
            message: "Book Publisher updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.LoadPub();
            this.pubObj = {
              id: 0,
              publisher: "",
              sequence: 0,
              campusID: this.$store.state.userData.cId,
            };
            var elem = this.$refs["publisher"];
            elem.state = undefined;
          }
        }
      }
    },
    async deletePub(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "BooksPublisher/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Book Publisher removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadPub();
      }
    },
    async LoadAuth() {
      var obj = {
        url:
          this.$store.state.domain +
          "BooksAuthor?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.authors = await this.get(obj);
      // console.log("aut", this.authors);
    },
    openAuth() {
      this.authObj = {
        id: 0,
        author: "",
        sequence: 0,
        campusID: this.$store.state.userData.cId,
      };

      this.sidebarButton = "Save";
      this.visibility3 = true;

      var elem = this.$refs["author"];
      elem.state = undefined;
      this.buttonIcon = "PlusIcon";
    },
    editAuth(item) {
      this.authObj = item;
      this.buttonIcon = "Edit2Icon";
      // console.log(this.authObj);
    },

    CheckAuth() {
      var elem = this.$refs["author"];
      if (this.authObj.author == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async deleteAuth(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "BooksAuthor/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Book Author removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadAuth();
      }
    },
    async saveAuth() {
      this.CheckAuth();
      if (this.CheckAuth() == true) {
        if (this.authObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "BooksAuthor?db=" +
              this.$store.state.userData.db,
            body: this.authObj,
            message: "Book Author added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.LoadAuth();
            this.authObj = {
              id: 0,
              author: "",
              sequence: 0,
              campusID: this.$store.state.userData.cId,
            };
            var elem = this.$refs["author"];
            elem.state = undefined;
          }
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "BooksAuthor/" +
              this.authObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.authObj,
            message: "Book Author updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.LoadAuth();
            this.authObj = {
              id: 0,
              author: "",
              sequence: 0,
              campusID: this.$store.state.userData.cId,
            };
            var elem = this.$refs["author"];
            elem.state = undefined;
          }
        }
      }
    },
    OpenDepartmentF() {
      this.visibility = true;
      //   this.depObj.id = 0;
      //   this.depObj.name = "";
      this.depObj.campusID = this.$store.state.userData.cId;
      this.sidebarTitle = "Add Fee";
      this.sidebarButton = "Save";
      //   var elem = this.$refs["depname"];
      //   elem.state = undefined;
    },

    AddOpen() {
      this.catObj = {
        id: 0,
        category: "",
        icon: "",
        status: "active",
        sequence: 0,
        campusID: this.$store.state.userData.cId,
      };
      this.sidebarTitle = "Add Category";
      this.sidebarButton = "Save";
      this.visibility1 = true;
      this.logoloading = false;
      var elem = this.$refs["CatName"];
      elem.state = undefined;
      var elem = this.$refs["icon"];
      elem.state = undefined;
    },
    deleteLogo() {
      this.catObj.icon = null;
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    selectlogo() {
      if (this.$refs.fileProfile.files[0] !== "") {
        this.logoloading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.fileProfile.files[0]);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);

            var fn1 = e.data.myresp[0].path;

            this.catObj.icon = fn1;

            // console.log(this.catObj.icon);
            this.logoloading = "loaded";
            this.CheckIcon();
          })
          .catch((err) => {
            console.log(err);
            this.logoloading = false;
          });
      }
    },

    EditCat(item) {
      this.catObj = item;
      this.sidebarTitle = "Edit Category";
      this.sidebarButton = "Update";
      this.visibility1 = true;
      if (this.catObj.icon !== null || this.catObj.icon !== "") {
        this.logoloading = "loaded";
      }
      var elem = this.$refs["CatName"];
      elem.state = undefined;
      var elem = this.$refs["icon"];
      elem.state = undefined;
    },

    async DeleteCat(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "BooksCategries/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Category removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData(0);
      }
    },

    CheckRequired(name) {
      var elem = this.$refs[name];
      if (this.myObj.name == "" || this.myObj.name > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }

      // return (elem.state = this.$v.depObj.name.required);
    },

    CheckTitle() {
      var elem = this.$refs["title"];
      if (this.myObj.title == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPdf() {
      var elem = this.$refs["bookPdf"];
      if (this.myObj.pdf == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDesc() {
      var elem = this.$refs["desc"];
      if (this.myObj.details == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCatId() {
      var elem = this.$refs["catId"];
      if (this.myObj.categoryID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPubId() {
      var elem = this.$refs["pubId"];
      if (this.myObj.publisherID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAuthId() {
      var elem = this.$refs["authId"];
      if (this.myObj.authorID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAmount() {
      var elem = this.$refs["amount"];
      if (this.mydefaultAmount == 0 || this.mydefaultAmount == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCatName() {
      var elem = this.$refs["CatName"];
      if (this.catObj.category == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckIcon() {
      var elem = this.$refs["icon"];
      // console.log(elem);
      if (this.catObj.icon == "" || this.catObj.icon == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckRecurrence() {
      var elem = this.$refs["recurrence"];
      if (this.myObj.recurrence == null || this.myObj.recurrence == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },
    hideDepSideBar() {
      this.visibility1 = false;
    },

    Form() {
      this.$router.push("/apps/schoolinfo");
    },
    checkStatus() {
      if (this.myObj.Status == "") {
        return (this.errors.status = true);
      } else {
        return (this.errors.status = false);
      }
    },
    OpenAddModal() {
      this.$bvModal.show("modal-login");
      this.myObj.flavourId = 0;
      this.myObj.vendorId = this.$store.state.userData.userID;
      this.myObj.Name = "";
      this.myObj.Status = "";
    },
    OpenEditModal(row) {
      this.$bvModal.show("modal-login");
      this.myObj.flavourId = row.flavourId;
      this.myObj.vendorId = row.vendorId;
      this.myObj.Name = row.Name;
      this.myObj.Status = row.Status;
    },

    async LoadData(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "BooksCategries?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      // console.log("cat", this.items);
      //   if (id == 0) {
      //     let catId = this.items[0].id;
      //     this.LoadGrid(catId);
      //   } else if (id == 1) {
      //     let catId = this.items[this.items.length - 1].id;
      //     this.LoadGrid(catId);
      //   } else {
      //     this.LoadGrid(id);
      //   }
    },
    async LoadGrid(id) {
      // this.current = id;
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Books?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        // "&feeTypeID=" +
        // id,
        token: this.$store.state.userData.token,
      };
      this.gridData = await this.get(obj);
      this.dataLoading = false;
      // console.log("grid", this.gridData);
    },

    async AddCategory() {
      this.CheckCatName();
      this.CheckIcon();
      if (this.CheckCatName() == true && this.CheckIcon() == true) {
        if (this.catObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "BooksCategries?db=" +
              this.$store.state.userData.db,
            body: this.catObj,
            message: "Book Category added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility1 = false;
          if (status) this.LoadData(1);
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "BooksCategries/" +
              this.catObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.catObj,
            message: "Book Categries updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility1 = false;
          if (status) this.LoadData(this.catObj.id);
        }
      }
    },

    async Add() {
      // console.log("obj:", this.myObj);
      this.CheckTitle();
      this.CheckPdf();
      // this.CheckDesc();
      this.CheckCatId();
      this.CheckAuthId();
      this.CheckPubId();
      if (
        this.CheckTitle() == false ||
        // this.CheckDesc() == false ||
        this.CheckPdf() == false ||
        this.CheckCatId() == false ||
        this.CheckPubId() == false ||
        this.CheckAuthId() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.myObj.defaultAmount = parseInt(this.mydefaultAmount);
        if (this.myObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Books?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Book added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.LoadGrid(1);
            // this.showForm = false;
          }
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "Books/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Book updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.showForm = false;
            this.LoadGrid(this.myObj.id);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.my-collapsing {
  height: 55px;
  overflow: hidden;
}
.my-collapsing.adjust {
  height: 39px;
}
.my-buttons {
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
}

.lib-form-right {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}

.lib-form-left {
  width: 20%;
}

@media (max-width: 1100px) {
  .lib-form-right {
    width: 75%;
  }
  .lib-form-left {
    width: 25%;
  }
}
@media (max-width: 992px) {
  .lib-form {
    flex-direction: column;
  }
  .lib-form-right {
    width: 100%;
  }
  .lib-form-left {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
